.portfolio-modal-title {
    width: 100%;
    text-align: center;
    margin-bottom: $gutter;
    z-index: 2;
    position: relative;
    
    svg {
        fill:color(white);
    }
    
    .logo-client {
        width: 187px;
        height: 60px;
        display: block;
        margin: 0 auto;
    }
    
    @include mappy-query(mobile) {
        margin-bottom: 15px;
        .logo-client {
            width: 120px;
            height: 50px;
        }    
    }
}


