%block-link {
	@include hide-text;
	text-indent: 300%;
	position: absolute;
	z-index: 20;
	top:0;
	left:0;
	right:0;
	bottom:0;
	display: block;
}