%full-screen {
    position: relative;
    width: 100%;
    height: 100vh;
}

%vertical-box-align {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}