.icon-svg, 
.logo-svg {
    //display: none;
    position: absolute;
    top:0;
    left:0;
	width: 100%;
	height: 100%;
	fill:inherit;
}
