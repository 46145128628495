%aspect-ratio {
    overflow: hidden;
    position: relative;

    &::before {
        display: block;
        padding-top: 56.25%;
        content: '';
    }
}

%aspect-video {
    position: relative;

    &::before {
        display: block;
        padding-top: 56.25%;
        width: 100%;
        height: 0;
        content: '';
    }

    > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        min-width: 200px;
    }
}
