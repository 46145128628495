.modal-overlay {
    background-image: radial-gradient(rgba(0,0,0,.2),rgba(0,0,0,.93));
    left:-100%;
//    background-color:rgba(color(black), .9);
    width: 100%;
    height: 100%;
    position: fixed;
    top:0;
    left:0;
    z-index: 899;
    opacity: 0;
}