.portfolio-media {
    width: 100%;
    clear: both;
    text-align: center;
    padding:$gutter/2 0 $gutter/2 0;
}

@include mappy-query(mobile) {
    .portfolio-media {
        white-space: nowrap;
	    overflow: hidden;
	    overflow-x: auto;
        padding:0;
        
		.portfolio-project-image  {
			//padding:0 5px;
			display: inline-block;
		}


    }
}