.navigation-list-item {    
    color:color(white);
    margin-left:7%;

    &:first-child {
        margin-left:0;
    }
}

@include mappy-query(mobile) {
    .navigation-list-item {
        margin-left: 0;
        margin-bottom: 10%;
    }    
}