.content-chapter {
    width: 70%;
    padding:0 $gutter;
    float:right;
    
    .intro {
        font-size: em(26);
        line-height: 1.5;
        margin-bottom: $gutter/2;
    }
    
    .text {
        font-family: $body-font-2;
        font-size: em(16);
        line-height: 1.6;
        margin-bottom: $gutter/2;
    }
}

@include mappy-query(mobile) {
    .content-chapter {
        width: 100%;
        padding:15px;
        
        .intro {
            font-size: em(20);
        }
        
        .text {
            font-size: em(14);
            line-height: 1.7;
        }
    }    
}

@include mappy-query(tablet) {
    .content-chapter {
        padding:0 30px;
    }
}
