/**
* Navigation mobile animation. Its pretty to work to put this in the same file.
* [1] workaround, margin fix, if navigation is open
*/
//@include mappy-bp(max $navigation-small-breakpoint) {
/*
    .content-wrapper {
        transition: transform .3s ease-in-out;
        position: relative;
    }
*/

    .navigation-list {
        transition: transform .3s ease-in-out, padding .3s ease-in-out;
    }

    .top-bar {
        //background-color: rgba(color(black), 0);
        transition: background .3s ease-in-out;
    }

    .navigation {
        @include transition(opacity .3s .6s ease-in-out, transform .1s .8s ease-in-out);
        @include transform(translate3d(-100%, 0, 0));
        opacity: 0;
    }
    
    .navigation-list-item {
        @include transform(scale(0));
        @include transition-property(transform);
    	@include transition-duration(.2s);
    	@include transition-timing-function(ease-in-out);
    	
        @for $i from 1 through 4 {
    		&:nth-child(#{$i}) {
    			$number:$i/10;
    			//@include fadeIn($delay:$number+s);
    			@include transition-delay($number+s);
    		}
    	}
    }

    .is--navigation-open {
        .top-bar {
            z-index: z-index(topbar) + 2;
            position: fixed;
            height: 100vh;
            //background-color: rgba(color(black), .8);
        }
        
        .navigation {
            @include transition(opacity .2s 0s ease-in-out, transform .1s 0s ease-in-out);
            @include transform(translate3d(0, 0, 0));
            opacity: 1;
        }
        
        .content-wrapper {
            z-index: z-index(topbar);
            //overflow: hidden;
            width: 100%;
            height: 100%;
        }
        
        .navigation-list-item {
            //@include transform(translate3d(0, 0, 0));	
			@include transform(scale(1));
        }
        
    }

