%animate-defer {
    .crop img[data-lazy-defer]:not(.is--deferred),
    .portfolio-project-image img[data-lazy-defer]:not(.is--deferred) {
        visibility: hidden;
    }

    .crop img[data-lazy-defer].is--deferred,
    .portfolio-project-image img[data-lazy-defer].is--deferred {
        animation: fade-in ($animation-time * 4) $animation-motion backwards;
    }
}
