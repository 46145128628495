$navigation-toggle-width: 68px !default;
$navigation-toggle-height: 68px !default;
$navigation-toggle-pad: 42px !default;
$navigation-toggle-bar-offset: -12px !default;
$hamburger-size: rem(3px) !default;
$navigation-border-color: rgba(#fff, .15) !default;

@import 'base';
@import 'navigation-toggle';
@import 'navigation-list-social';
@import 'navigation-list';
@import 'navigation-list-item';
@import 'navigation-item-link';
@import 'navigation-animation';
