%is--hidden-mobile {
    //display: none !important;

    @include mappy-bp(small) {
        display: inline-block;
    }

    @include mappy-query(mobile) {
        display: none !important;
    }
}

%is--hidden-tablet {
    @include mappy-bp(small) {
        display: inline-block;
    }

    @include mappy-query(tablet) {
        display: none !important;
    }
}

/**
 * Visually hide an element
 */
%visually-hidden {
    @include visually-hidden;
}

/**
 * Target mobile devices
 * @query mobile
 */
@include mappy-query(mobile) {
    /**
     * Wrap the visually hidden mixin in a mobile query
     */
    %visually-hidden-mobile {
        @include visually-hidden;
    }
}

