.portfolio-client-list-item {
    .crop {
        @extend %aspect-ratio;
        background-color:color(black);
        //position: relative;
        z-index: 1;
        
        &::before {padding-top: 40.4%;}
/*
        &::after {
            @include radial-gradient(rgba(black,0), rgba(black, 0.5) 80%);
            @include transition(opacity .3s ease-in-out);
            position: absolute;
            top:0;
            left:0;
            z-index: 1;
            display: block;
            width: 100%;
            height: 100%;
            content:'';
            //opacity: .8;
        }
*/
        
        img {
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: auto;     
        }
    }
        
/*
    &:hover .crop::after {
        opacity: .8;
    }
*/
    
/*
    &.is--active {
        .crop {
            &::after {display: none;}
        }
    }
*/
}
