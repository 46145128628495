.intro-tagline {
    font-size: em(16);
    font-weight: 300;
    font-family: $body-font-2;
    margin-top:-20px;
    strong {
        @extend %uppercase;
        font-weight: 700;
    }
}

@include mappy-query(mobile) {
    .intro-tagline {
        margin-top: 0;
        //font-size: em(12);
        font-size: 3vw;
    }
}
