/**
 * Element is hidden
 */
.is--hidden {
    display: none !important;
}

/**
 * Element Visibility (opacity switch)
 */
.is--animation {
    transition: 150ms ease-in-out 100ms;
}

.is--invisible {
    opacity: 0;
}

.is--visible {
    opacity: 1;
}

.is--fixed {
    position: fixed;
}

.is--active {
    display: inline-block;
}

/**
 * Hide on mobile
 */
.is--hidden-mobile {
    @extend %is--hidden-mobile;
}

/**
 * Show on mobile
 */
.is--visible-mobile {
    display: block !important;
}

@include mappy-bp(small) {
   .is--visible-mobile {
        display: none !important;
    }
}

/**
 * Show on mobile
 */
.is--visible-mobile-inline {
    display: inline-block;
}

@include mappy-bp(small) {
    .is--visible-mobile-inline {
        display: none !important;
    }
}

/**
 * Hide on tablet
 */
.is--hidden-tablet {
    @extend %is--hidden-tablet;
}

/**
 * Visually hidden
 */
.is--visually-hidden {
    @extend %visually-hidden;
}

/**
 * Floating Classes
 */
.right {
    float: right; // NOTE: need !important flag for floating columns
}

.left {
    float: left;
}

/**
 * Color Classes
 */
.is--highlight {
    background-color: color(grey, 100);
}

/**
 * Text align Classes
 */
.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}


/**
 * Clearfix
 */
.clearfix {
    @extend %clearfix;
}


.disable--scrolling {
	overflow: hidden;
	height: 100%;
	width: 100%;
}

.fixed--scrolling {
    @extend .disable--scrolling;
    position: absolute;
    
}