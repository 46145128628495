.top-bar {
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    z-index: 80;
}

@include mappy-query(mobile) {
    .top-bar {
        @include linear-gradient(rgba(#000,.3) 40%, transparent);
        
        background-color: transparent;
        width: 100%;
        height: 80px;
    }
}

.disable--scrolling {
    .top-bar {
        z-index: 1;
    }
}