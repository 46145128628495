.navigation-item-link {
	@include transition(transform .1s  ease-in-out);
    display: block;
    position: relative;
    padding:0 10px;
    overflow: hidden;
    font-size: em(38);
    font-family: $body-font-2;
    font-weight: 300;
	
    &:hover {
		//@include transform(scale(1.1));
		
		span {
			@include transform(translate3d(0,-100%, 0));
			
		}
	}
			
	span {
		@include transition(transform .2s  ease-in-out);
		position: relative;
		display: inline-block;
		
		&::before {
			@include transform(translate3d(0,0, 0));
			position: absolute;
			top: 100%;
			content: attr(data-hover);
			font-weight: 700;
		}

	}   
}

.navigation-item-link {
    &.is--current {
        span {
            @include transform(translate3d(0,-100%, 0));
            cursor: default;
        }
    }
}

@include mappy-query(mobile) {
    .navigation-item-link {
        font-size: em(32);
    }
}
