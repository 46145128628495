.column-row {
    @extend %vertical-box-align;
    flex-direction: column;
    
    .column-wrapper {
        width: 90%;
        position: relative;
        max-width: 1200px;
        padding-bottom: 2*$gutter;
    }
}

@include mappy-query(mobile) {
    .column-row {
        display: block;
        //overflow-y: auto;
        
        .column-wrapper {
            width: 100%;
            padding-top: 100px;
        }
        
    }    
}
