/**
 * Colums
 */
.col-1-2 {
    width: 100%;
}

/**
 * Colums mediaqueries
 */
@include mappy-bp(medium) {
    .col-1-2 {
        width: 50%;
    }
}

.col-1-3 {
	@include mappy-bp(small) {
		@include span-columns(4 of 12);
	}
}


.col-2-3 {
	@include mappy-bp(small) {
		@include span-columns(8 of 12);
	}
}

.col-2-5 {
	
	@include span-columns(7 of 12);
	
	@include mappy-query(mobile) {
		width: 100%;
	}
}