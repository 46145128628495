.column-definition-list {
    margin-bottom: $gutter/2;
    line-height: 1.3;
    
    &:first-of-type {
        padding-top: $gutter/8;
    }
    
    dt {
        margin-bottom: $gutter/8;
        font-size: em(18);
        font-weight: 900;
        
        em {
            font-weight: 300;
        }
        
        .icon {
            vertical-align: middle;
            margin-right: 2px;
            width: 30px;
        }
    }
    
    dd {
        @extend %body-font-2;
        font-size: em(13);
        
        display: inline-block;
        
        &::after {
            content:',';
        }
        
        &:last-of-type:after {
            content:'.';
        }
        
    }
}

@include mappy-query(mobile) {
   .column-definition-list {
       dd {
           font-size: 0.95em;
       }
   } 
}
