/*
.button {
    @extend %button;
    @include button-color($bg-color: transparent, $button-color: color(white));

    @include mappy-bp(small) {
        @include button-color($bg-color: transparent, $button-color: color(blue));
    }
}

.button-filled {
    @extend %button;
    @include button-color($bg-color: color(blue), $button-color: color(white));
    border: 0;
}

.button-round {
    @extend %button-round;
    @include button-color($bg-color: transparent, $button-color: color(blue));
}

.button-round-filled {
    @extend %button-round;
    @include button-color($bg-color: color(blue), $button-color: color(white));

    border: 0;
}

.button-round-white-border {
    @extend %button-round;
    @include button-color($bg-color: transparent, $button-color: color(white));
}

.button-white-border {
    @extend %button;
    @include button-color($bg-color: transparent, $button-color: color(white));

    &:hover {
        @include button-color($bg-color: transparent, $button-color: darken(color(white), 10%));
    }
}
*/

.button-social {
    @extend %button-social;
    @include button-color($bg-color: transparent, $button-color: color(white));
    @include hide-text;

    .icon {
        position: absolute;
        left:0;
        top:50%;
        margin-top:-12px;
    }
}
