/**
* Navigation
* 
*/
.navigation {  
    @include linear-gradient(rgba(#000,.1), #000, rgba(#000,.1)  );
    
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100vh;
    z-index: 101;
    
}

@include mappy-query(mobile) {
    .navigation {   
        background-color:rgba(#000,.8);
    }

}