/*
* Portfolio-modal, for animation see animation.scss
*
*/

.portfolio-modal {
    //transition: opacity .3s .3s ease-in-out, transform .3s ease-in-out;
    //transform:translate3d(0, 100%, 0 );
    position: fixed;
//    min-height: 100%;
    overflow: hidden;
    overflow-y:auto;
    width: 100%;
    //z-index: -1;
    top:0;
    padding:$gutter;
    //opacity: 0;
    //position: relative;
    z-index: 900;
    .row {
        width: 80%;
        max-width: 1150px;
        position: relative;
    }
    
    
    &.is--active {
        height: 100%;
    }
/*
    &.is--active {
         z-index: 900;
         
         + .modal-overlay {
             z-index: 899;
         }
    }
*/
}

@include mappy-query(mobile) {
    .portfolio-modal {
        padding:0 5px;
        
        .row {width: 100%; padding-top:10px;}
    }  
}

@include mappy-bp(orientation portrait) {
    .portfolio-modal {
        .row {
            width: 96%;
            
        }
    }
}
