.social-navigation-item {
    @include transition(transform .2s ease-in-out);
    width: 44px;
    height: 44px;
    position: relative;
    display: inline-block;
    
    a {
        width: 100%;
        height: 100%;
        text-indent: -210%;
        display: block;
        overflow: hidden;
        position: relative;
       
        .icon {
            text-indent: 0;
            width: 40%;
            position: absolute;
            height: 40%;
            top:30%;
            left:30%;
        }
    }
    
    &:hover{
        @include transform(scale(1.1));
    }
}