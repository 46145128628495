$base-line-height: 44px;
$white: rgb(255,255,255);
$off-white: rgba($white, 0.2);
$spin-duration: 1s;
$pulse-duration: 750ms;


.is--loading {
    .loading-wrapper {
        display: block;
        animation: fade-in .2s .5s linear;
    }
    .loading-bar {
        animation: spin $spin-duration infinite linear;
        //&::before {animation: loading .6s linear infinite;}
    }
}

.loading-wrapper {
    display: none;
    position: fixed;
    top:0;
    left:0;
    right: 0;
    bottom:0;
    z-index: 100;
    //background-color: rgba(#000, .4);
}


/*
.loading-bar {
  height: 2px;
  width: 100%;
  position: absolute;
  top:50%;
  margin-top:-1px;
  overflow: hidden;
  //background-color: #ddd;
  
}
.loading-bar:before{
  display: block;
  position: absolute;
  content: "";
  left: -200px;
  width: 200px;
  height: 4px;
  background-color: rgba(#fff,.6);
  
}

@keyframes loading {
    from {left: -200px; width: 30%;}
    50% {width: 30%;}
    70% {width: 70%;}
    80% { left: 50%;}
    95% {left: 120%;}
    to {left: 100%;}
}
*/




.loading-bar {
    border-radius: 50%;
    width: $base-line-height;
    height: $base-line-height;
    border: .25rem solid $off-white;
    border-top-color: $white;
    
    
    position: absolute;
    //top:50%;
    left:50%;
    z-index: 100;
    margin-top: -$base-line-height/2;
    margin-left: -$base-line-height/2;
    bottom:$gutter;
  
}
