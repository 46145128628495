.portfolio-item {
    position: relative;
    animation-name: bounceIn;
    animation-duration: 1s;
    animation-delay: .2s;
    animation-fill-mode: both;
    width: 280px;
    display: inline-block;
    //margin-left: 10%;
    padding:2% 4%;
    //border:1px dotted rgba(#fff, .4);
   
    
    &:first-child {
        margin-left: 0;
    }
    
    &:hover {
        z-index: 20;
    } 
}

@for $i from 1 through 12{
    .portfolio-item:nth-child(#{$i}) {
        $number:$i/7;
        animation-delay: $number+s;
	}
}

.logo-client {
    position: relative;
    width: 100%;
    height: auto;
    fill:color(white);
    
    &::before {
        content:'';
        padding-bottom:40%;
        width: 100%;
        display: block;
    }
}

@include mappy-query(mobile) {
    .portfolio-item {
        padding:4% 15%;
        //border-bottom:1px solid rgba(#fff,.2);
        background-color: rgba(#fff,.1);
        margin-top: 5px;
        
        &:first-child {
            //border-top:1px solid rgba(#fff,.2);
        }
    }    
}

@include mappy-bp(small) {
    .portfolio-item {
        margin-bottom:15px;
    }
}