.social-navigation {
    position: absolute;
    //left:em($navigation-toggle-width);
    right: rem(25);
    top:25px;
    margin-left: 25px;
}

@include mappy-query(mobile) {
    .social-navigation {
        top:10px;
        right: 5px;
    }
}