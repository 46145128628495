/**
 * Grid
 */
.grid-screen-full {
   @extend %full-screen;
   //animation: fade-in .5s .4s linear;
   //overflow: hidden;
   //position: fixed;
   opacity: 0;
   z-index: 10;
   
   &.animation--in {
       position: absolute;
       top:0;
       left:0;
       animation: appear .5s .2s ease-in-out both;
   }
   
   &.animation--out {
       animation: fade-out .5s .2 ease-in-out both;
   }
}
