.portfolio-item-link {
    
    cursor: pointer;
    display: block;
    
    .logo-client {
        @include transition(transform .2s ease-in-out);    
    }
    
    &:hover .logo-client {
        @include transform(scale(1.07));
    }    
}