.navigation-list {
    @extend %vertical-box-align;
    flex-direction: row;
    padding-bottom: $gutter;
    
}

@include mappy-query(mobile) {
    .navigation-list {
        flex-direction: column;
        padding-bottom: 0;
    }
}