/**
 * Navigation toggle button active state
 */
.navigation-toggle.is--active span {
    @include transform(rotate(180deg));
    height: 0;

    /**
     * Resize the :before and :after
     */
    &::before,
    &::after {
        width: ($navigation-toggle-pad);
    }

    /**
     * Transform the :before shape to part of an arrow
     */
    &::before {
        @include transform(translateX(6px) translateY(14px) rotate(-45deg));
        top: 0;
    }

    /**
     * Transform the :after shape to part of an arrow
     */
    &::after {
        @include transform(translateX(6px) translateY(-14px) rotate(45deg));
        bottom: 0;
    }
}

/**
 * Position the navigation toggle button on mobile devices
 */
.navigation-toggle {
    @include hide-text;
    text-indent: 1000px;
    //background: red;
    z-index: z-index(modal) + 2;
    display: block;
    position: absolute;
    top: 15px;
    left: 15px;
    width: rem($navigation-toggle-width);
    height: rem($navigation-toggle-height);
    cursor: pointer;

    :focus {
        outline: none;
    }
    
    
    @include mappy-bp(small) {
        &:hover {
            span {
                &::before,
                &::after {
                    width: 100%;
                }
            }
        }
    }
}

/**
 * Rotate the navigation toggle span to create a CSS hamburger menu
 */
.navigation-toggle span {
    top: ($navigation-toggle-width / 2);
    left: 50%;
    margin-left: -($navigation-toggle-pad / 2);

    /**
     * Draw the hamburger menu lines
     */
    &,
    &::before,
    &::after {
        display: block;
        position: absolute;
        width: $navigation-toggle-pad;
        height: $hamburger-size;
        background-color: color(white);
        content: '';
        transition: transform ($animation-time * 3), width $animation-time;
    }

    /**
     * Draw the hamburger menu top line
     */
    &::before {
        top: $navigation-toggle-bar-offset;
        width: 80%;
        transition: transform ($animation-time * 3), width $animation-time;
        transform-origin: top left;
    }

    /**
     * Draw the hamburger menu bottom line
     */
    &::after {
        bottom: $navigation-toggle-bar-offset;
        transition: transform ($animation-time * 3), width $animation-time;
        transform-origin: bottom left;
        width: 70%
    }
    
}

@include mappy-query(mobile) { 
    .navigation-toggle {
        left:5px;
        top:0;
    }    
    
    .navigation-toggle span {
        width: 34px;
        margin-left:-18px;
        
        &:before {
            top:-10px;
        }
        
        &:after {
            bottom:-10px;
        }
    }
    
    .navigation-toggle.is--active span  {
        
        &::before {
            width: 100%;
            @include transform(translateX(3px) translateY(12px) rotate(-45deg));
        }
        
        &::after {
            width: 100%;
            @include transform(translateX(3px) translateY(-10px) rotate(45deg));
        }
        
    }
    
    
}

