.portfolio-note-wrapper {
    position: absolute;
    font-size: rem(13);
    font-weight: 300;
    display: inline-block;
    padding-left:24px;
    width: 0;
    height: 0;
    overflow: hidden;
    left: 30%;
    will-change: height, width; 
}

.portfolio-sep {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 10px;
    border: 1px solid rgba(#fff, .3);
    border-width: 0 0 1px 1px;
    will-change: color; 
}


.portfolio-note {
    display: block;
    width: 145px;
    padding: 7px 8px;
    border: 1px solid rgba(#fff, .3);
    color: color(white, $alpha: 0);
    background-color: color(white, $alpha:.05);
    border-radius: 3px;

    strong {
        display: block;
        clear: both;
        margin: 2px 0;
        font-size: rem(15);
        line-height: 1.2;
    }
}


@include mappy-bp(small) {

    .portfolio-item:hover .portfolio-note-wrapper {
        animation-name: block-in;
        animation-duration: .3s;
        animation-delay: .2s;
        animation-fill-mode: both;
        
        .portfolio-note {
            animation-name: color-in;
            animation-delay: .5s;
            animation-duration: .3s;
            animation-fill-mode: both;
        }
    }
}

@keyframes color-in {
    0% { color: rgba(#fff, 0); }
    100% { color: rgba(#fff, .85);}
}

@keyframes block-in {
    0% { height: 0; width: 0px;  }
    40% { height: 10px; width: 24px;}
    100% { height: 76px; width: 170px;}
}

