/**
 * Style title used above several teasers and block elements
 * @see ../modules/footer/_title.scss
 *      ../modules/teaser/highlight/_base.scss
 */


%title-base {
    //color: color(white);
    font-family: $header-font;
    font-weight: 700;
}

%title-page {
   font-size: em(38);
   line-height: 1.2;
   
    @include mappy-query(mobile) {
        font-size: em(30);
    }
    
    @include mappy-bp(x-large) {
        font-size: em(48);
    }
}
 
/**
 * Heading margin bottom
 * @see 'sources/scss/base/_rows.scss'
 * @see 'sources/scss/modules/article/_header.scss'
 */
%title-margin {
    margin-bottom: em(36);

    @include mappy-query(mobile) {
        margin-bottom: em(26);
    }
}
