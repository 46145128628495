.column-box {
    @extend %vertical-box-align;
    flex-direction: row;
    
    .column-wrapper {
        @extend %vertical-box-align;
        max-width: 1000px;
        height: auto;
    }
}

@include mappy-query(mobile) {
    .column-box {
        display: block;
        padding-top: 100px;
        overflow-y: auto;
        
        .column-wrapper {
            flex-direction: column;
            padding:0 20px;
        }
    }
}

@include mappy-query(tablet) {
    .column-box {
        
        .column-wrapper {
            max-width: 940px;
        }
        
    }
}