.column-content {
    width: 74%;
    padding-left:1.5*$gutter;
    font-size: em(17);
    
    p {
        margin-bottom: $gutter/2;
        font-family: $body-font-2;
        
        &:first-of-type {
            font-family: $body-font;
            font-weight: 700;
            font-size: 1.2em;
        }
        
        a {
            border-bottom:1px dashed;
            display: inline-block;
            
            &:hover {
                animation-name: link-ani;
                animation-duration: .3s;
                animation-fill-mode: both;
            }
        }
    }
}

.column-content-contact {
    @extend .column-content;
    width: 60%;
}

@include mappy-query(mobile) {
    .column-content {
        width: 100%;
        padding:0;
        font-size: em(14);
        
         p:first-of-type {
            font-size: 1em;
            line-height: 1.4;
        }
    }      
}

@include mappy-query(tablet) {
    .column-content {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@keyframes link-ani {
    0% {  transform: translate3d(0px, 0, 0);  }
    50% {  transform: translate3d(1px, 1px, 0);  }
    100% { transform: translate3d(0, 0px, 0);}
}
