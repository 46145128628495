.column-row-quote {
    margin: -$gutter/1.2 auto $gutter/2 auto;
    font-weight: 300;
    font-size: em(18);
    font-family: $body-font-2;
    text-align: center;
    letter-spacing: -0.5px;
    //font-style: italic;
    max-width: em(410);
    line-height: 1.2;
    opacity: .8;
}

@include mappy-query(mobile) {
    .column-row-quote {display: none;}
}
