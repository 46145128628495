/**
 * Animation appear placeholder
 */
%animation-appear {
    @include animation-appear;
}

@keyframes shake {
  from, to {
    //-webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    //-webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    //-webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}


@keyframes wobble {
  from {
   // -webkit-transform: none;
    transform: none;
  }

  15% {
    //-webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }

  30% {
    //-webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }

  45% {
    //-webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  60% {
    //-webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  75% {
    //-webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  to {
    //-webkit-transform: none;
    transform: none;
  }
}

/**
 * Keyframes for animation appear
 */
@keyframes appear {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

/**
 * Simple fade-in keyframes
 */
@keyframes fade-in {
    0% { opacity: 0;  }
    100% { opacity: 1; }
}


/**
 * Simple fade-in keyframes
 */
@keyframes fade-out {
    0% { opacity: 1;  }
    100% { opacity: 0; }
}

/**
 * Simple slide-in keyframes
 */
/*
@keyframes slide-in {
    0% { top: 0; opacity: 1; }
    100% { top: 100vh; opacity: 0; }
}
*/

/*
@keyframes slide-out {
    0% { top: 100vh; opacity: 0; }
    100% { top: 0; opacity: 1; }
}
*/

@keyframes slide-in {
    0% { transform:translate3d(0, 0, 0); opacity: 1; }
    100% { transform:translate3d(0, 100vh, 0); opacity: 0; }
}

@keyframes slide-out {
    0% { transform:translate3d(0, 100vh, 0); opacity: 0; }
    100% { transform:translate3d(0, 0, 0); opacity: 1; }
}


@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }


@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(.9, .9, .9);
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(.97, .97, .97);
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
