%button-base {
    display: inline-block;
    height: 46px;
    font-weight: 700;
    border: 2px solid;
    line-height: rem(44);
    cursor: pointer;
}

%button {
    @extend %button-base;
    padding: 0 30px;
    font-size: rem(15);
    border-radius: 8px;

    &:active {
        box-shadow: inset 0px 2px 2px #000;
    }

    .icon-svg {
        display: inline-block;
        Fill: inherit;
        width: 14px;
        height: 14px;
        vertical-align: middle;
    }

    .icon-right {
        float: right;
        margin-right: 6px;
        margin-left: 8px;
    }

    .icon-left {
        margin-right: 22px;
        margin-left:-8px;
        float:left;
    }
}

%button-round {
    @extend %button;
    padding: 0;
    width: 46px;
    border-radius: 50%;
    font-weight: 300;
    position: relative;

    .icon-svg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 14px;
        height: 14px;
        margin: -7px 0 0 -7px;
    }
}

%button-social {
    $icon-size: 20px;
    @extend %button-base;

    position: relative;
    width: 46px;
    border-radius: 50%;

    .icon-svg {
        position: absolute;
        top: 50%;
        left: 25%;
        width: $icon-size;
        height: $icon-size;
        margin: -($icon-size / 2) 0 0 -($icon-size / 2);
    }
}
