.portfolio-client-list-item {
    position: relative;

    .content {
        position: relative;
        transition: max-height 0.70s cubic-bezier(0, 1, 1, 1);
        max-height: 0px;
        overflow: hidden;
        width: 100%;
        will-change: max-height;
        
        &.is--active {
            max-height: 10000px;  
            margin-bottom: -10px;
            transition: max-height 0.70s ease-in-out;   
        }
    }
}

.portfolio-client-item-title {
    transition:transform .3s ease-in-out, left .3s ease-in-out, text-align .2s ease-in-out, font-size .3s ease-in-out;
    transform: translate3d(-35%,-50%, 0) scale(0.7);
    will-change: text-align, transform, left;
    font-size: em(52);

    &.is--active {
        transform:translate3d(-50%,-50%, 0) scale(1);
        left:50%;
        text-align: center;

    }
    
    @include mappy-bp(orientation portrait) {
        transform:scale(.7) translate3d(-35%,-50%, 0);
    }
    
    
    @include mappy-query(mobile) {
        transform:scale(.7) translate3d(0px,-50%, 0);
        font-size: 1.5em;
        width: 100%;
        padding:0 15%;
        left:0;
        text-align: center;
        text-shadow: 0 1px 5px rgba(#000, .4);    
    }
    
    @include mappy-query(tablet) {
        font-size: em(48);
    }
    
    @include mappy-bp(x-large) {
        font-size: em(60);
    }  
}




/*
.portfolio-client-item-title {
    @include transition(opacity .3s .1s ease-in-out);
    //opacity: 0;
}

.portfolio-client-list-item:hover {
    .portfolio-client-item-title  {
        //@include transform(translate3d(-80px,-50%, 0));
        //opacity: 1;
        
    }
}
  
*/