.intro-title {
    @extend %title-base;
    @extend %uppercase;
    
    font-size: em(82);
    
}

@include mappy-query(mobile) {
    .intro-title {
        font-size: 12vw;
        line-height: 1.1;
    }
}