.button-group {
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.button-group-item {
    float: left;
    margin: 0 13px 0 0;

    &:last-child {
        margin: 0;
    }
}
