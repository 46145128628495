.portfolio-meta {
    @extend %is--hidden-mobile;
    width: 30%;
    float:left;
    
    .meta-list {
        max-width:150px;
        margin-bottom: $gutter/2; 
    }
    
    dd {
        font-size: em(14);
        display: inline;
        
        &::after {
            content:', ';
        }
        
        &:last-child::after {
            content:'';
        }
    }
}