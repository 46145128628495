/**
 * Row
 */
.row {
    display: block;
    position: relative;
    margin: 0 auto;
    width: 94%;
    //max-width: $max-width;
    transition: padding $animation-properties;
    will-change: padding;
    clear: both;

    &::after {
        content: '';
        clear: both;
        display: table;
    }
}

@include mappy-query(mobile) {
    .row {
        width: 100%;
        padding:0 10px;
    }
}

