.portfolio-client-list-item {
    @extend %animate-defer;
    animation-name: slide-in;
    animation-duration: .5s;
    animation-fill-mode: both;
    position: relative;
    margin-bottom: 0px;
    width: 100%;
    
    .block-link {
        @extend %block-link;
        cursor: pointer;
        width: 100%;
        background: transparent;
        border: 0;
        outline: none;

    }
}

@for $i from 1 through 12{
    .portfolio-client-list-item:nth-child(#{$i}) {
        $number:$i/4;
        animation-delay: $number+s;
	}
}


.portfolio-modal.is--active {
    .portfolio-client-list-item {
        animation-name: slide-out;
    }
}