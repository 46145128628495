.column-title {
    @extend %title-base;
    @extend %title-page;
    
    margin-top: - em(2);
    align-self: flex-start;
    text-align: right;
    flex: 1;
}

@include mappy-query(mobile) {
    .column-title {
        margin-bottom: 15px;
        text-align: left;
    }
}
