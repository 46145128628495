@mixin button-color($bg-color:false, $button-color:color(blue)) {
    @if ($bg-color) {
        background-color: $bg-color;
    }
    color: $button-color;
    fill: $button-color;

    &:hover {
        color: darken($button-color, 10%);
        fill: darken($button-color, 10%);

        @if ($bg-color) {
            background-color: darken($bg-color, 10%);
        }
    }
}
