.portfolio-detail {
    //display: none;
    padding:40px 50px;
    background-color:color(white);
    color:color(black);
}

@include mappy-query(mobile) {
    .portfolio-detail {
        padding:0px 0 30px 0;
    }
}

@include mappy-query(tablet) {
    .portfolio-detail {
        padding:40px 50px;
    }
}

@include mappy-bp(large) {
    .portfolio-detail {
        padding:$gutter 2*$gutter;
    }
}
