/**
 * Color settings
 * [1] Styleguide colors
 * [2] Dividers colors
 * [3] Font colors
 * [4] Overal colors
 */

/**
 * Color key base variable
 */
$color-key: 500;

/**
 * Color pallette
 */
$colors: (
    black: (
        500: #12191b
    ),
    white: (
        500: #fff
    ),
    red: (
        500: #9b0005
    )
);

$social: (
    facebook: (
        base: #5172ab
    ),
    twitter: (
        base: #00b7ee
    ),
    google: (
        base: #e1453b
    ),
    mail: (
        base: #4b606c
    )
);
