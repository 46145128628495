.portfolio-modal {
    transition: opacity .3s .7s ease-in-out, right .3s 1s ease-in-out;
    //transform:translate3d(0, 100%, 0 );
    //transition: opacity .3s .3s ease-in-out, right .3s ease-in-out;
    //transition: opacity .3s ease-in-out, right .3s .5s ease-in-out;
    right:-100%;
    opacity: 0;
    will-change: opacity, top;
    
    + .modal-overlay {
        left:-100%;
        transition: opacity .3s 1s ease-in-out, left .1s 1.5s linear;
    }
    
    &.is--active {
        transition: opacity .1s ease-in-out, right .01s ease-in-out;
        //transition: opacity .3s .2s ease-in-out, right .3s .4s ease-in-out;
        //transition: opacity .8s ease-in-out;
        opacity: 1;
        //transform:translate3d(0, 0, 0 );
        right: 0;
        
        + .modal-overlay {
            transition: opacity .8s ease-in-out, left .01s linear;
            opacity: 1;
            left:0;
        }   
    }
}