.column-row-title {
    @extend %title-base;
    @extend %title-page;

    text-align: center;
    margin-bottom: $gutter;
}

@include mappy-query(mobile) {
    .column-row-title {
        margin-bottom: 15px;
    }
}
